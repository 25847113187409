/// including variables ///

// colors
$cr : #e72f02;


// media queries
$d1  : 1850px;
$d   : 1170px;
$ta  : 969px;
$ta2 : 767px;
$ph  : 479px;
$ph2 : 320px;


// transitions
$trs  : ease-in-out 0.2s;
$trs3 : ease-in-out 0.3s;
