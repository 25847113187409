@import "generated/sprite-svg";
@import "generated/sprite-png";
@import "bourbon/bourbon";
@import "_reset";
@import "_variables.sass";
@import "_mixins";
@import '_fonts';
@import "_base";



.header {
  height: 108px;
  padding-top: 25px;
  background-color: white;
  box-shadow: 0 15px 46px rgba(0, 0, 0, 0.35);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 5;
  @include m(p1) {
    padding-top: 15px;
    height: 98px; }
  &_logo {
    display: block;
    float: left;
    margin: 10px 0 0 10px;
    @include m(t2) {
      margin-left: 0; }
    @include m(p1) {
      margin-top: 0; }
    &_icon {
      display: block;
      @include m(t) {
        width: 170px; }
      @include m(t2) {
        width: 150px; }
      @include m(p1) {
        width: 120px; } } }
  &_contacts {
    display: block;
    float: left;
    margin: 10px 0 0 45px;
    @include m(d) {
      margin: 10px 0 0 20px; }
    @include m(t2) {
      margin: 0;
      float: right; } }
  &_phone {
    display: block;
    color: #181819;
    font-family: mb;
    font-size: 26px;
    font-weight: 400;
    line-height: 18px;
    text-transform: uppercase;
    @include m(t2) {
      font-size: 22px; }
    @include m(p1) {
      font-size: 18px;
      margin-top: 3px; } }
  &_worktime {
    display: block;
    margin-top: 7px;
    opacity: 0.8;
    color: #181819;
    font-family: mr;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    @include m(t2) {
      display: none; } }
  &_recall {
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    margin-left: 107px;
    width: 266px;
    height: 57px;
    background-color: #e72f02;
    box-shadow: 0 13px 29px rgba(200, 39, 0, 0.42);
    color: white;
    font-family: mr;
    font-size: 16px;
    font-weight: 400;
    transition: $trs;
    @include m(d) {
      margin-left: 20px; }
    @include m(t) {
      float: right;
      margin-left: 0; }
    @include m(t2) {
      margin-top: 10px;
      width: 220px;
      height: 40px; }
    @include m(p1) {
      width: 70%;
      margin: 13px 15% 0;
      font-size: 12px; }
    &:hover {
      background-color: lighten($cr, 5%);
      box-shadow: 0 13px 29px lighten(rgba(200, 39, 0, 0.42), 5%); } }
  &_adress {
    display: block;
    position: relative;
    float: right;
    margin-top: 8px;
    color: #414a59;
    font-family: mr;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    @include m(t) {
      display: none; }
    &:before {
      content: '';
      position: absolute;
      left: -15px;
      top: 2px;
      @include s('place'); } } }

.adv {
  margin-top: 108px;
  padding: 100px 0 224px;
  @include bg('adv-bg');
  background-attachment: fixed;
  @include m(p1) {
    margin-top: 98px; }
  &_head {
    display: block;
    font-family: meb;
    color: #fff;
    font-size: 65px;
    font-weight: 400;
    line-height: 71px;
    @include m(d) {
      font-size: 62px; }
    @include m(t) {
      font-size: 56px;
      line-height: 1.2; }
    @include m(t2) {
      font-size: 50px; }
    @include m(p1) {
      font-size: 40px;
      br {
        display: none; } }
    span {
      color: #e72f02; } }
  &_subhead {
    display: block;
    margin-top: 27px;
    color: white;
    font-family: mr;
    font-size: 18px;
    font-weight: 400;
    line-height: 29px; }
  &_items {
    margin-top: 55px;
    max-width: 725px;
    @include cl;
    display: flex;
    @include m(t2) {
      flex-wrap: wrap;
      margin-top: 35px; } }
  &_item {
    flex: 1;
    padding: 0 10px;
    position: relative;
    @include m(t2) {
      width: 50%;
      margin-top: 20px;
      flex: none; }
    &:not(:last-of-type) {
      &:before {
        content: '';
        position: absolute;
        top: 35px;
        right: 10px;
        @include s('adv-items-del');
        @include m(t2) {
          display: none; } } }
    &_icon {
      display: block;
      &--1 {
        @include s('adv-item1'); }
      &--2 {
        @include s('adv-item2'); }
      &--3 {
        @include s('adv-item3');
        transform: translateY(-11px); }
      &--4 {
        @include s('adv-item4'); } }
    &_content {
      display: block;
      margin-top: 17px;
      color: white;
      font-family: mb;
      font-size: 17px;
      font-weight: 400;
      line-height: 24px; } } }

.contact {
  position: relative;
  &_block {
    position: relative;
    top: -150px;
    padding: 80px 80px 60px;
    background-color: white;
    box-shadow: 0 17px 68px rgba(53, 77, 91, 0.15);
    padding: 55px 40px 40px;
    @include m(t) {
      top: -100px; }
    @include m(t2) {
      top: -70px;
      padding: 40px 20px; } }
  &_car {
    position: absolute;
    right: 0;
    bottom: 150px;
    z-index: 2;
    width: 493px;
    height: 410px;
    @include bg('form-car-img', 'png');
    @include m(d1) {
      width: 250px;
      height: 250px; }
    @include m(d) {
      display: none; } }
  &_head {
    display: block;
    color: #1f1c1c;
    font-family: meb;
    font-size: 50px;
    font-weight: 400;
    line-height: 49px;
    @include m(d) {
      font-size: 42px; }
    @include m(t) {
      font-size: 34px; }
    @include m(t2) {
      font-size: 30px;
      line-height: 1.3; } }
  &_subhead {
    display: block;
    margin-top: 45px;
    color: #1f1c1c;
    font-family: mr;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    @include m(t2) {
      margin-top: 25px; } }
  &_form {
    display: block;
    @include cl;
    &_row {
      display: flex;
      margin: 65px -1.5% 0;
      @include cl;
      @include m(t2) {
        flex-wrap: wrap; }
      @include m(p1) {
        margin: 30px -1.5% 0; } }
    &_right {
      float: right;
      width: 47%;
      margin: 0 1.5%;
      @include m(t2) {
        width: 97%;
        margin: 20px 1.5% 0; }
      @include m(p1) {
        margin: 15px 1.5% 0; }
      &_vin {
        display: block;
        position: relative;
        height: 100%;
        width: 100%;
        border: 1px solid #ccd0d7;
        padding: 20px 30px;
        color: rgba(#596075, 0.89);
        font-family: mr;
        font-size: 16px;
        font-weight: 400;
        line-height: 23px;
        resize: none;
        @include m(t2) {
          min-height: 100px; }
        @include m(p1) {
          min-height: 150px;
          padding: 15px; } } }
    &_left {
      float: left;
      width: 47%;
      margin: 0 1.5%;
      @include m(t2) {
        width: 97%; }
      &_input {
        display: block;
        width: 100%;
        height: 57px;
        border: 1px solid #ccd0d7;
        padding: 0 30px;
        color: rgba(#596075, 0.89);
        font-family: mr;
        font-size: 16px;
        font-weight: 400;
        line-height: 23px;
        @include m(p1) {
          padding: 0 15px;
          height: 45px; }
        &.phoneinput {
          margin-top: 30px;
          @include m(p1) {
            margin-top: 15px; } } }
      &_details {
        display: block;
        margin-top: 30px;
        height: 105px;
        width: 100%;
        border: 1px solid #ccd0d7;
        padding: 20px 30px;
        color: rgba(#596075, 0.89);
        font-family: mr;
        font-size: 16px;
        font-weight: 400;
        line-height: 23px;
        resize: none;
        @include m(p1) {
          height: 150px;
          margin-top: 20px;
          padding: 15px; } } }
    &_type {
      margin: 37px 0 0;
      align-items: center;
      &_item {
        display: flex;
        align-items: center;
        float: left;
        cursor: pointer;
        user-select: none;
        &:not(:first-of-type) {
          margin-left: 65px;
          @include m(p1) {
            margin: 20px 0 0 0; } }
        @include m(p1) {
          float: none;
          width: 100%; }
        &_input {
          border: 0;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
          &:checked {
            &+label {
              background-color: $cr;
              border: 1px solid $cr; } } }
        &_label {
          display: block;
          float: left;
          width: 13px;
          height: 13px;
          background-color: white;
          border: 1px solid #b5bac4;
          transition: $trs;
          position: relative;
          cursor: pointer;
          &:before {
            content: '';
            position: absolute;
            z-index: 2;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @include s('form-galka'); } }
        &_content {
          display: block;
          float: left;
          margin-left: 17px;
          color: rgba(#596075, 0.89);
          font-family: mr;
          font-size: 16px;
          font-weight: 400;
          line-height: 23px; } } }
    &_submit {
      display: block;
      margin-top: 80px;
      border: none;
      width: 266px;
      height: 57px;
      background-color: #e72f02;
      box-shadow: 0 13px 29px rgba(200, 39, 0, 0.42);
      color: white;
      font-family: mr;
      font-size: 16px;
      font-weight: 400;
      cursor: pointer;
      transition: $trs;
      @include m(p1) {
        margin-top: 30px;
        width: 100%; }
      &:hover {
        background-color: lighten($cr, 5%); } } }
  &_ranger {
    max-width: 750px;
    padding-bottom: 50px;
    @include m(t) {
      max-width: 100%; }
    .irs-grid-pol, .irs-single, .irs-grid-pol.small, .irs-max, .irs-min {
      display: none; }
    .irs-grid-text {
      display: block;
      top: 50px;
      color: rgba(#24262d, 0.9);
      font-family: mr;
      font-size: 16px;
      font-weight: 400;
      line-height: 23px;
      @include m(t2) {
        font-size: 12px;
        max-width: 70px;
        white-space: unset;
        line-height: 1.2;
        top: 30px; }
      @include m(p1) {
        transform: translateX(-10px); }
      &:last-child {
        @include m(t) {
          transform: translateX(-45px); }
        @include m(t2) {
          transform: translateX(0); }
        @include m(p1) {
          transform: translateX(-10px); } } }
    .irs-slider {
      top: 15px;
      width: 46px;
      height: 46px;
      background: #e72f02;
      border: none;
      box-shadow: 0 14px 49px rgba(200, 39, 0, 0.54);
      @include m(p1) {
        width: 30px;
        height: 30px;
        top: 20px;
        box-shadow: 0 5px 30px rgba(200, 39, 0, 0.54); } }
    .irs-line-left, .irs-line-mid, .irs-line-right {
      height: 7px; }
    .irs-line {
      height: 7px;
      border-radius: 0;
      border: none;
      background: #dddee1; }
    .irs-bar, .irs-bar-edge {
      height: 7px;
      background: $cr;
      border-radius: 0;
      border: none; }
    .irs-bar-edge {
      width: 25px; } } }

.shop {
  &_head {
    display: block;
    color: #1f1c1c;
    font-family: meb;
    font-size: 50px;
    line-height: 49px;
    @include m(d) {
      font-size: 42px; }
    @include m(t) {
      font-size: 34px; }
    @include m(t2) {
      font-size: 30px;
      line-height: 1.3; } }
  &_search {
    display: flex;
    margin-top: 75px;
    position: relative;
    @include cl;
    @include m(t) {
      margin-top: 55px; }
    @include m(t2) {
      margin-top: 40px; }
    &_icon {
      display: block;
      position: absolute;
      top: 19px;
      left: 26px;
      @include s('search-icon');
      @include m(p1) {
        top: 11px;
        left: 6px;
        transform: scale(0.6); } }
    &_input {
      display: block;
      flex: 1;
      height: 59px;
      background-color: #ebebed;
      border-radius: 30px 0 0 30px;
      padding: 0 75px;
      border: none;
      color: rgba(#596075, 0.6);
      font-family: mb;
      font-size: 16px;
      font-weight: 400;
      @include m(t2) {
        padding: 0 15px 0 65px;
        font-size: 14px; }
      @include m(p1) {
        padding: 0 10px 0 33px;
        font-size: 12px;
        height: 45px; } }
    &_submit {
      display: block;
      cursor: pointer;
      float: right;
      width: 266px;
      height: 59px;
      background-color: #e72f02;
      transition: $trs;
      border-radius: 0 30px 30px 0;
      border: none;
      color: white;
      font-family: mr;
      font-size: 16px;
      font-weight: 400;
      @include m(t2) {
        width: auto;
        padding: 0 30px;
        font-size: 14px; }
      @include m(p1) {
        padding: 0 10px;
        font-size: 12px;
        height: 45px; }
      &:hover {
        background-color: lighten($cr, 5%); } } }
  &_items {
    margin: 75px -20px 0;
    display: flex;
    @include m(d) {
      margin: 75px 0 0; }
    @include m(t2) {
      flex-wrap: wrap; }
    @include m(p1) {
      margin: 30px 0 0; } }
  &_item {
    flex: 1;
    margin: 0 20px;
    padding: 0 20px;
    height: 315px;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top center !important;
    @include m(d) {
      margin: 0 10px;
      padding: 0 10px;
      height: 260px; }
    @include m(t) {
      height: 220px; }
    @include m(t2) {
      flex: none;
      width: 100%;
      margin: 20px 0;
      height: 170px;
      background-position: center !important; }
    &_name {
      display: block;
      margin-top: 265px;
      color: white;
      font-family: mb;
      font-size: 14px;
      letter-spacing: -0.5px;
      line-height: 29px;
      text-transform: uppercase;
      @include m(d) {
        margin-top: 215px;
        font-size: 12px;
        line-height: 1.2;
        vertical-align: middle; }
      @include m(t) {
        margin-top: 180px; }
      @include m(t2) {
        margin-top: 140px; }
      &:after {
        content: '';
        display: inline-block;
        margin-left: 10px;
        margin-top: -3px;
        @include s('shop-next-icon');
        @include m(t) {
          margin-top: 0;
          transform: translateY(2px); } } } } }

.about {
  margin-top: 50px;
  &_head {
    display: block;
    color: #596075;
    line-height: 23px;
    font-family: mb;
    font-size: 22px; }
  &_content {
    display: block;
    margin-top: 25px;
    font-family: mr;
    font-size: 16px;
    color: #596075;
    font-weight: 400;
    line-height: 23px; }
  &_lead {
    margin-top: 55px;
    @include cl; }
  &_in-shop {
    display: block;
    float: left;
    width: 266px;
    height: 57px;
    background-color: #e72f02;
    box-shadow: 0 13px 29px rgba(200, 39, 0, 0.42);
    transition: $trs;
    line-height: 60px;
    text-align: center;
    color: white;
    font-family: mr;
    font-size: 16px;
    @include m(t2) {
      width: 200px; }
    @include m(p1) {
      width: 100%;
      float: none; }
    &:hover {
      background-color: lighten(#e72f02, 5%); } }
  &_register {
    display: block;
    float: left;
    margin-left: 55px;
    width: 266px;
    height: 57px;
    border: 1px solid #e72f02;
    background-color: transparent;
    line-height: 55px;
    text-align: center;
    transition: $trs;
    color: #e72f02;
    font-family: mr;
    font-size: 16px;
    @include m(t2) {
      width: 200px;
      margin-left: 0;
      float: right; }
    @include m(p1) {
      width: 100%;
      float: none;
      margin-top: 20px; }
    &:hover {
      background-color: $cr;
      color: #fff;
      box-shadow: 0 13px 29px rgba(200, 39, 0, 0.42); } } }

.reviews {
  margin-top: 180px;
  .owl-carousel .owl-stage-outer {
    padding-bottom: 20px; }
  &_head {
    display: block;
    color: #1f1c1c;
    font-family: meb;
    font-size: 50px;
    line-height: 49px;
    @include m(d) {
      font-size: 42px; }
    @include m(t) {
      font-size: 34px; }
    @include m(p1) {
      font-size: 26px; }
    &_block {
      position: relative; } }
  &_carousel {
    margin-top: 110px;
    @include m(t2) {
      margin-top: 50px; }
    @include m(p1) {
      margin-top: 40px; }
    &_prev {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 60px;
      width: 47px;
      height: 47px;
      background-color: #e72f02;
      transition: $trs;
      cursor: pointer;
      @include m(p1) {
        width: 30px;
        height: 30px;
        right: 35px; }
      &:hover {
        background-color: lighten($cr, 5%); }
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include s('slider-prev'); } }
    &_next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      width: 47px;
      height: 47px;
      background-color: #e72f02;
      transition: $trs;
      cursor: pointer;
      @include m(p1) {
        width: 30px;
        height: 30px; }
      &:hover {
        background-color: lighten($cr, 5%); }
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include s('slider-next'); } }
    &_item {
      padding: 50px 53px 50px;
      background-color: white;
      box-shadow: 0 21px 57px rgba(0, 0, 0, 0.07);
      @include m(t) {
        padding: 30px; }
      @include m(p1) {
        padding: 20px 15px;
        box-shadow: none;
        border: 1px solid rgba(#777, 0.05); }
      &_top {
        display: flex;
        align-items: center; }
      &_img {
        display: block;
        float: left;
        border-radius: 50%;
        width: 57px;
        height: 57px; }
      &_head {
        display: block;
        margin-left: 29px;
        color: #191817;
        font-family: mb;
        font-size: 20px;
        line-height: 40px;
        @include m(p1) {
          font-size: 16px; } }
      &_content {
        display: block;
        margin-top: 40px;
        color: rgba(#191817, 0.7);
        font-family: mr;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        @include m(p1) {
          font-size: 14px;
          line-height: 1.3; } } } } }

.contacts {
  margin-top: 205px;
  position: relative;
  @include cl;
  @include m(t) {
    margin-top: 100px; }
  &_map {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
    @include m(t2) {
      position: relative;
      height: 320px; } }
  &_block {
    z-index: 2;
    position: relative;
    margin: 110px 0 90px 5%;
    padding: 60px 65px 60px;
    width: 489px;
    height: 442px;
    background-color: white;
    box-shadow: 0 17px 68px rgba(53, 77, 91, 0.15);
    @include m(t) {
      width: auto;
      height: auto;
      padding: 40px 30px;
      margin: 70px 0 70px 40px;
      float: left; }
    @include m(t2) {
      float: none;
      width: 100%;
      margin: 30px 0 0 0; } }
  &_head {
    display: block;
    color: #1f1c1c;
    font-family: meb;
    font-size: 50px;
    line-height: 49px;
    @include m(t) {
      font-size: 36px; } }
  &_adress {
    display: block;
    margin-top: 65px;
    color: #414a59;
    font-family: mr;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    @include m(t) {
      margin-top: 35px;
      font-size: 14px; }
    &:before {
      content: '';
      display: inline-block;
      margin-right: 10px;
      @include s('contacts-place'); } }
  &_phone {
    display: block;
    margin-top: 35px;
    color: #181819;
    font-family: mb;
    font-size: 26px;
    text-transform: uppercase; }
  &_worktime {
    display: block;
    margin-top: 10px;
    color: rgba(#181819, 0.8);
    font-family: mr;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    text-transform: uppercase; }
  &_recall {
    display: block;
    margin-top: 55px;
    width: 266px;
    height: 57px;
    background-color: #e72f02;
    box-shadow: 0 13px 29px rgba(200, 39, 0, 0.42);
    line-height: 60px;
    text-align: center;
    transition: $trs;
    color: white;
    font-family: mr;
    font-size: 16px;
    font-weight: 400;
    &:hover {
      background-color: lighten($cr, 5%); } } }

.checkform {
  display: none;
  &_head {
    display: block;
    text-align: center;
    font-size: 16px;
    font-family: mb; }
  &_form {
    @include cl; }
  &_wrapper {
    margin-top: 15px;
    display: flex;
    @include cl; }
  &_input {
    display: block;
    float: left;
    flex: 1;
    height: 35px;
    border-radius: 5px 0 0 5px;
    border: {
      top: 1px solid #ccc;
      left: 1px solid #ccc;
      bottom: 1px solid #ccc; }
    padding: 0 10px;
    font-family: mr;
    font-size: 14px; }
  &_submit {
    float: left;
    width: 100px;
    height: 35px;
    border: none;
    border-radius: 0 5px 5px 0;
    color: #fff;
    background: $cr;
    font-size: 14px;
    font-family: mr;
    cursor: pointer; } }

.recall {
  display: none;
  &_head {
    display: block;
    text-align: center;
    font-size: 16px;
    font-family: mb; }
  &_input {
    display: block;
    height: 35px;
    width: 100%;
    max-width: 360px;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 0 10px;
    font-family: mr;
    font-size: 14px;
    margin-top: 15px; }
  &_submit {
    margin-top: 15px;
    height: 35px;
    display: block;
    width: 100%;
    max-width: 360px;
    border-radius: 5px;
    background: $cr;
    color: #fff;
    font-size: 14px;
    border: none;
    cursor: pointer; } }




















































































































































































