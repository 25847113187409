.icon-facebook {
  width: 0.53em;
  height: 1em;
  fill: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: "";
  content: none;
}

q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "mr";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/MullerRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/MullerRegular.woff") format("woff"), url("../fonts/MullerRegular.ttf") format("truetype"), url("../fonts/MullerRegular.svg#mr") format("svg");
}

@font-face {
  font-family: "mb";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/MullerBold.eot?#iefix") format("embedded-opentype"), url("../fonts/MullerBold.woff") format("woff"), url("../fonts/MullerBold.ttf") format("truetype"), url("../fonts/MullerBold.svg#mb") format("svg");
}

@font-face {
  font-family: "meb";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/MullerExtraBold.eot?#iefix") format("embedded-opentype"), url("../fonts/MullerExtraBold.woff") format("woff"), url("../fonts/MullerExtraBold.ttf") format("truetype"), url("../fonts/MullerExtraBold.svg#meb") format("svg");
}

* {
  box-sizing: border-box;
  outline: none;
  appearance: none;
}

a {
  text-decoration: none;
}

a:hover, a:active, a:visited {
  text-decoration: none;
}

body {
  font-family: mr, sans-serif;
  font-weight: normal;
  font-size: 14px;
  min-width: 320px;
  position: relative;
  line-height: 1;
  -webkit-font-smoothing: subpixel-antialiased;
  overflow-x: hidden;
  background: #fff;
}

input::placeholder, textarea::placeholder {
  color: inherit;
  font-size: inherit;
  font-family: inherit;
}

.hidden {
  display: none;
}

.clearfix::after {
  clear: both;
  content: "";
  display: table;
}

.clearfix:before {
  content: '';
  display: table;
  clear: both;
}

.wrapper {
  width: 1170px;
  margin: 0 auto;
  position: relative;
}

.wrapper:before, .wrapper:after {
  content: '';
  display: table;
  clear: both;
}

@media only screen and (max-width: 1170px) {
  .wrapper {
    width: 970px;
    padding: 0 15px;
  }
}

@media only screen and (max-width: 969px) {
  .wrapper {
    width: 768px;
  }
}

@media only screen and (max-width: 767px) {
  .wrapper {
    width: 480px;
  }
}

@media only screen and (max-width: 479px) {
  .wrapper {
    width: 320px;
  }
}

@media only screen and (max-width: 320px) {
  .wrapper {
    width: 320px;
  }
}

.header {
  height: 108px;
  padding-top: 25px;
  background-color: white;
  box-shadow: 0 15px 46px rgba(0, 0, 0, 0.35);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 5;
}

@media only screen and (max-width: 479px) {
  .header {
    padding-top: 15px;
    height: 98px;
  }
}

.header_logo {
  display: block;
  float: left;
  margin: 10px 0 0 10px;
}

@media only screen and (max-width: 767px) {
  .header_logo {
    margin-left: 0;
  }
}

@media only screen and (max-width: 479px) {
  .header_logo {
    margin-top: 0;
  }
}

.header_logo_icon {
  display: block;
}

@media only screen and (max-width: 969px) {
  .header_logo_icon {
    width: 170px;
  }
}

@media only screen and (max-width: 767px) {
  .header_logo_icon {
    width: 150px;
  }
}

@media only screen and (max-width: 479px) {
  .header_logo_icon {
    width: 120px;
  }
}

.header_contacts {
  display: block;
  float: left;
  margin: 10px 0 0 45px;
}

@media only screen and (max-width: 1170px) {
  .header_contacts {
    margin: 10px 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .header_contacts {
    margin: 0;
    float: right;
  }
}

.header_phone {
  display: block;
  color: #181819;
  font-family: mb;
  font-size: 26px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .header_phone {
    font-size: 22px;
  }
}

@media only screen and (max-width: 479px) {
  .header_phone {
    font-size: 18px;
    margin-top: 3px;
  }
}

.header_worktime {
  display: block;
  margin-top: 7px;
  opacity: 0.8;
  color: #181819;
  font-family: mr;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .header_worktime {
    display: none;
  }
}

.header_recall {
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  margin-left: 107px;
  width: 266px;
  height: 57px;
  background-color: #e72f02;
  box-shadow: 0 13px 29px rgba(200, 39, 0, 0.42);
  color: white;
  font-family: mr;
  font-size: 16px;
  font-weight: 400;
  transition: ease-in-out 0.2s;
}

@media only screen and (max-width: 1170px) {
  .header_recall {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 969px) {
  .header_recall {
    float: right;
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .header_recall {
    margin-top: 10px;
    width: 220px;
    height: 40px;
  }
}

@media only screen and (max-width: 479px) {
  .header_recall {
    width: 70%;
    margin: 13px 15% 0;
    font-size: 12px;
  }
}

.header_recall:hover {
  background-color: #fd3606;
  box-shadow: 0 13px 29px rgba(226, 44, 0, 0.42);
}

.header_adress {
  display: block;
  position: relative;
  float: right;
  margin-top: 8px;
  color: #414a59;
  font-family: mr;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
}

@media only screen and (max-width: 969px) {
  .header_adress {
    display: none;
  }
}

.header_adress:before {
  content: '';
  position: absolute;
  left: -15px;
  top: 2px;
  background-image: url("../img/sprite.png");
  width: 12px;
  height: 16px;
  background-position: -42px -161px;
}

.adv {
  margin-top: 108px;
  padding: 100px 0 224px;
  background: url(../img/adv-bg.jpg) no-repeat top center/cover;
  background-attachment: fixed;
}

@media only screen and (max-width: 479px) {
  .adv {
    margin-top: 98px;
  }
}

.adv_head {
  display: block;
  font-family: meb;
  color: #fff;
  font-size: 65px;
  font-weight: 400;
  line-height: 71px;
}

@media only screen and (max-width: 1170px) {
  .adv_head {
    font-size: 62px;
  }
}

@media only screen and (max-width: 969px) {
  .adv_head {
    font-size: 56px;
    line-height: 1.2;
  }
}

@media only screen and (max-width: 767px) {
  .adv_head {
    font-size: 50px;
  }
}

@media only screen and (max-width: 479px) {
  .adv_head {
    font-size: 40px;
  }
  .adv_head br {
    display: none;
  }
}

.adv_head span {
  color: #e72f02;
}

.adv_subhead {
  display: block;
  margin-top: 27px;
  color: white;
  font-family: mr;
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
}

.adv_items {
  margin-top: 55px;
  max-width: 725px;
  display: flex;
}

.adv_items:before, .adv_items:after {
  content: '';
  display: table;
  clear: both;
}

@media only screen and (max-width: 767px) {
  .adv_items {
    flex-wrap: wrap;
    margin-top: 35px;
  }
}

.adv_item {
  flex: 1;
  padding: 0 10px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .adv_item {
    width: 50%;
    margin-top: 20px;
    flex: none;
  }
}

.adv_item:not(:last-of-type):before {
  content: '';
  position: absolute;
  top: 35px;
  right: 10px;
  background-image: url("../img/sprite.png");
  width: 68px;
  height: 3px;
  background-position: 0px -148px;
}

@media only screen and (max-width: 767px) {
  .adv_item:not(:last-of-type):before {
    display: none;
  }
}

.adv_item_icon {
  display: block;
}

.adv_item_icon--1 {
  background-image: url("../img/sprite.png");
  width: 69px;
  height: 69px;
  background-position: 0px -69px;
}

.adv_item_icon--2 {
  background-image: url("../img/sprite.png");
  width: 65px;
  height: 65px;
  background-position: -97px 0px;
}

.adv_item_icon--3 {
  background-image: url("../img/sprite.png");
  width: 60px;
  height: 68px;
  background-position: -172px 0px;
  transform: translateY(-11px);
}

.adv_item_icon--4 {
  background-image: url("../img/sprite.png");
  width: 87px;
  height: 59px;
  background-position: 0px 0px;
}

.adv_item_content {
  display: block;
  margin-top: 17px;
  color: white;
  font-family: mb;
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
}

.contact {
  position: relative;
}

.contact_block {
  position: relative;
  top: -150px;
  padding: 80px 80px 60px;
  background-color: white;
  box-shadow: 0 17px 68px rgba(53, 77, 91, 0.15);
  padding: 55px 40px 40px;
}

@media only screen and (max-width: 969px) {
  .contact_block {
    top: -100px;
  }
}

@media only screen and (max-width: 767px) {
  .contact_block {
    top: -70px;
    padding: 40px 20px;
  }
}

.contact_car {
  position: absolute;
  right: 0;
  bottom: 150px;
  z-index: 2;
  width: 493px;
  height: 410px;
  background: url(../img/form-car-img.png) no-repeat top center/cover;
}

@media only screen and (max-width: 1850px) {
  .contact_car {
    width: 250px;
    height: 250px;
  }
}

@media only screen and (max-width: 1170px) {
  .contact_car {
    display: none;
  }
}

.contact_head {
  display: block;
  color: #1f1c1c;
  font-family: meb;
  font-size: 50px;
  font-weight: 400;
  line-height: 49px;
}

@media only screen and (max-width: 1170px) {
  .contact_head {
    font-size: 42px;
  }
}

@media only screen and (max-width: 969px) {
  .contact_head {
    font-size: 34px;
  }
}

@media only screen and (max-width: 767px) {
  .contact_head {
    font-size: 30px;
    line-height: 1.3;
  }
}

.contact_subhead {
  display: block;
  margin-top: 45px;
  color: #1f1c1c;
  font-family: mr;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
}

@media only screen and (max-width: 767px) {
  .contact_subhead {
    margin-top: 25px;
  }
}

.contact_form {
  display: block;
}

.contact_form:before, .contact_form:after {
  content: '';
  display: table;
  clear: both;
}

.contact_form_row {
  display: flex;
  margin: 65px -1.5% 0;
}

.contact_form_row:before, .contact_form_row:after {
  content: '';
  display: table;
  clear: both;
}

@media only screen and (max-width: 767px) {
  .contact_form_row {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 479px) {
  .contact_form_row {
    margin: 30px -1.5% 0;
  }
}

.contact_form_right {
  float: right;
  width: 47%;
  margin: 0 1.5%;
}

@media only screen and (max-width: 767px) {
  .contact_form_right {
    width: 97%;
    margin: 20px 1.5% 0;
  }
}

@media only screen and (max-width: 479px) {
  .contact_form_right {
    margin: 15px 1.5% 0;
  }
}

.contact_form_right_vin {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
  border: 1px solid #ccd0d7;
  padding: 20px 30px;
  color: rgba(89, 96, 117, 0.89);
  font-family: mr;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  resize: none;
}

@media only screen and (max-width: 767px) {
  .contact_form_right_vin {
    min-height: 100px;
  }
}

@media only screen and (max-width: 479px) {
  .contact_form_right_vin {
    min-height: 150px;
    padding: 15px;
  }
}

.contact_form_left {
  float: left;
  width: 47%;
  margin: 0 1.5%;
}

@media only screen and (max-width: 767px) {
  .contact_form_left {
    width: 97%;
  }
}

.contact_form_left_input {
  display: block;
  width: 100%;
  height: 57px;
  border: 1px solid #ccd0d7;
  padding: 0 30px;
  color: rgba(89, 96, 117, 0.89);
  font-family: mr;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
}

@media only screen and (max-width: 479px) {
  .contact_form_left_input {
    padding: 0 15px;
    height: 45px;
  }
}

.contact_form_left_input.phoneinput {
  margin-top: 30px;
}

@media only screen and (max-width: 479px) {
  .contact_form_left_input.phoneinput {
    margin-top: 15px;
  }
}

.contact_form_left_details {
  display: block;
  margin-top: 30px;
  height: 105px;
  width: 100%;
  border: 1px solid #ccd0d7;
  padding: 20px 30px;
  color: rgba(89, 96, 117, 0.89);
  font-family: mr;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  resize: none;
}

@media only screen and (max-width: 479px) {
  .contact_form_left_details {
    height: 150px;
    margin-top: 20px;
    padding: 15px;
  }
}

.contact_form_type {
  margin: 37px 0 0;
  align-items: center;
}

.contact_form_type_item {
  display: flex;
  align-items: center;
  float: left;
  cursor: pointer;
  user-select: none;
}

.contact_form_type_item:not(:first-of-type) {
  margin-left: 65px;
}

@media only screen and (max-width: 479px) {
  .contact_form_type_item:not(:first-of-type) {
    margin: 20px 0 0 0;
  }
}

@media only screen and (max-width: 479px) {
  .contact_form_type_item {
    float: none;
    width: 100%;
  }
}

.contact_form_type_item_input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.contact_form_type_item_input:checked + label {
  background-color: #e72f02;
  border: 1px solid #e72f02;
}

.contact_form_type_item_label {
  display: block;
  float: left;
  width: 13px;
  height: 13px;
  background-color: white;
  border: 1px solid #b5bac4;
  transition: ease-in-out 0.2s;
  position: relative;
  cursor: pointer;
}

.contact_form_type_item_label:before {
  content: '';
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url("../img/sprite.png");
  width: 8px;
  height: 6px;
  background-position: -172px -126px;
}

.contact_form_type_item_content {
  display: block;
  float: left;
  margin-left: 17px;
  color: rgba(89, 96, 117, 0.89);
  font-family: mr;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
}

.contact_form_submit {
  display: block;
  margin-top: 80px;
  border: none;
  width: 266px;
  height: 57px;
  background-color: #e72f02;
  box-shadow: 0 13px 29px rgba(200, 39, 0, 0.42);
  color: white;
  font-family: mr;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  transition: ease-in-out 0.2s;
}

@media only screen and (max-width: 479px) {
  .contact_form_submit {
    margin-top: 30px;
    width: 100%;
  }
}

.contact_form_submit:hover {
  background-color: #fd3606;
}

.contact_ranger {
  max-width: 750px;
  padding-bottom: 50px;
}

@media only screen and (max-width: 969px) {
  .contact_ranger {
    max-width: 100%;
  }
}

.contact_ranger .irs-grid-pol, .contact_ranger .irs-single, .contact_ranger .irs-grid-pol.small, .contact_ranger .irs-max, .contact_ranger .irs-min {
  display: none;
}

.contact_ranger .irs-grid-text {
  display: block;
  top: 50px;
  color: rgba(36, 38, 45, 0.9);
  font-family: mr;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
}

@media only screen and (max-width: 767px) {
  .contact_ranger .irs-grid-text {
    font-size: 12px;
    max-width: 70px;
    white-space: unset;
    line-height: 1.2;
    top: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .contact_ranger .irs-grid-text {
    transform: translateX(-10px);
  }
}

@media only screen and (max-width: 969px) {
  .contact_ranger .irs-grid-text:last-child {
    transform: translateX(-45px);
  }
}

@media only screen and (max-width: 767px) {
  .contact_ranger .irs-grid-text:last-child {
    transform: translateX(0);
  }
}

@media only screen and (max-width: 479px) {
  .contact_ranger .irs-grid-text:last-child {
    transform: translateX(-10px);
  }
}

.contact_ranger .irs-slider {
  top: 15px;
  width: 46px;
  height: 46px;
  background: #e72f02;
  border: none;
  box-shadow: 0 14px 49px rgba(200, 39, 0, 0.54);
}

@media only screen and (max-width: 479px) {
  .contact_ranger .irs-slider {
    width: 30px;
    height: 30px;
    top: 20px;
    box-shadow: 0 5px 30px rgba(200, 39, 0, 0.54);
  }
}

.contact_ranger .irs-line-left, .contact_ranger .irs-line-mid, .contact_ranger .irs-line-right {
  height: 7px;
}

.contact_ranger .irs-line {
  height: 7px;
  border-radius: 0;
  border: none;
  background: #dddee1;
}

.contact_ranger .irs-bar, .contact_ranger .irs-bar-edge {
  height: 7px;
  background: #e72f02;
  border-radius: 0;
  border: none;
}

.contact_ranger .irs-bar-edge {
  width: 25px;
}

.shop_head {
  display: block;
  color: #1f1c1c;
  font-family: meb;
  font-size: 50px;
  line-height: 49px;
}

@media only screen and (max-width: 1170px) {
  .shop_head {
    font-size: 42px;
  }
}

@media only screen and (max-width: 969px) {
  .shop_head {
    font-size: 34px;
  }
}

@media only screen and (max-width: 767px) {
  .shop_head {
    font-size: 30px;
    line-height: 1.3;
  }
}

.shop_search {
  display: flex;
  margin-top: 75px;
  position: relative;
}

.shop_search:before, .shop_search:after {
  content: '';
  display: table;
  clear: both;
}

@media only screen and (max-width: 969px) {
  .shop_search {
    margin-top: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .shop_search {
    margin-top: 40px;
  }
}

.shop_search_icon {
  display: block;
  position: absolute;
  top: 19px;
  left: 26px;
  background-image: url("../img/sprite.png");
  width: 23px;
  height: 23px;
  background-position: -202px -78px;
}

@media only screen and (max-width: 479px) {
  .shop_search_icon {
    top: 11px;
    left: 6px;
    transform: scale(0.6);
  }
}

.shop_search_input {
  display: block;
  flex: 1;
  height: 59px;
  background-color: #ebebed;
  border-radius: 30px 0 0 30px;
  padding: 0 75px;
  border: none;
  color: rgba(89, 96, 117, 0.6);
  font-family: mb;
  font-size: 16px;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .shop_search_input {
    padding: 0 15px 0 65px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 479px) {
  .shop_search_input {
    padding: 0 10px 0 33px;
    font-size: 12px;
    height: 45px;
  }
}

.shop_search_submit {
  display: block;
  cursor: pointer;
  float: right;
  width: 266px;
  height: 59px;
  background-color: #e72f02;
  transition: ease-in-out 0.2s;
  border-radius: 0 30px 30px 0;
  border: none;
  color: white;
  font-family: mr;
  font-size: 16px;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .shop_search_submit {
    width: auto;
    padding: 0 30px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 479px) {
  .shop_search_submit {
    padding: 0 10px;
    font-size: 12px;
    height: 45px;
  }
}

.shop_search_submit:hover {
  background-color: #fd3606;
}

.shop_items {
  margin: 75px -20px 0;
  display: flex;
}

@media only screen and (max-width: 1170px) {
  .shop_items {
    margin: 75px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .shop_items {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 479px) {
  .shop_items {
    margin: 30px 0 0;
  }
}

.shop_item {
  flex: 1;
  margin: 0 20px;
  padding: 0 20px;
  height: 315px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: top center !important;
}

@media only screen and (max-width: 1170px) {
  .shop_item {
    margin: 0 10px;
    padding: 0 10px;
    height: 260px;
  }
}

@media only screen and (max-width: 969px) {
  .shop_item {
    height: 220px;
  }
}

@media only screen and (max-width: 767px) {
  .shop_item {
    flex: none;
    width: 100%;
    margin: 20px 0;
    height: 170px;
    background-position: center !important;
  }
}

.shop_item_name {
  display: block;
  margin-top: 265px;
  color: white;
  font-family: mb;
  font-size: 14px;
  letter-spacing: -0.5px;
  line-height: 29px;
  text-transform: uppercase;
}

@media only screen and (max-width: 1170px) {
  .shop_item_name {
    margin-top: 215px;
    font-size: 12px;
    line-height: 1.2;
    vertical-align: middle;
  }
}

@media only screen and (max-width: 969px) {
  .shop_item_name {
    margin-top: 180px;
  }
}

@media only screen and (max-width: 767px) {
  .shop_item_name {
    margin-top: 140px;
  }
}

.shop_item_name:after {
  content: '';
  display: inline-block;
  margin-left: 10px;
  margin-top: -3px;
  background-image: url("../img/sprite.png");
  width: 19px;
  height: 13px;
  background-position: -97px -100px;
}

@media only screen and (max-width: 969px) {
  .shop_item_name:after {
    margin-top: 0;
    transform: translateY(2px);
  }
}

.about {
  margin-top: 50px;
}

.about_head {
  display: block;
  color: #596075;
  line-height: 23px;
  font-family: mb;
  font-size: 22px;
}

.about_content {
  display: block;
  margin-top: 25px;
  font-family: mr;
  font-size: 16px;
  color: #596075;
  font-weight: 400;
  line-height: 23px;
}

.about_lead {
  margin-top: 55px;
}

.about_lead:before, .about_lead:after {
  content: '';
  display: table;
  clear: both;
}

.about_in-shop {
  display: block;
  float: left;
  width: 266px;
  height: 57px;
  background-color: #e72f02;
  box-shadow: 0 13px 29px rgba(200, 39, 0, 0.42);
  transition: ease-in-out 0.2s;
  line-height: 60px;
  text-align: center;
  color: white;
  font-family: mr;
  font-size: 16px;
}

@media only screen and (max-width: 767px) {
  .about_in-shop {
    width: 200px;
  }
}

@media only screen and (max-width: 479px) {
  .about_in-shop {
    width: 100%;
    float: none;
  }
}

.about_in-shop:hover {
  background-color: #fd3606;
}

.about_register {
  display: block;
  float: left;
  margin-left: 55px;
  width: 266px;
  height: 57px;
  border: 1px solid #e72f02;
  background-color: transparent;
  line-height: 55px;
  text-align: center;
  transition: ease-in-out 0.2s;
  color: #e72f02;
  font-family: mr;
  font-size: 16px;
}

@media only screen and (max-width: 767px) {
  .about_register {
    width: 200px;
    margin-left: 0;
    float: right;
  }
}

@media only screen and (max-width: 479px) {
  .about_register {
    width: 100%;
    float: none;
    margin-top: 20px;
  }
}

.about_register:hover {
  background-color: #e72f02;
  color: #fff;
  box-shadow: 0 13px 29px rgba(200, 39, 0, 0.42);
}

.reviews {
  margin-top: 180px;
}

.reviews .owl-carousel .owl-stage-outer {
  padding-bottom: 20px;
}

.reviews_head {
  display: block;
  color: #1f1c1c;
  font-family: meb;
  font-size: 50px;
  line-height: 49px;
}

@media only screen and (max-width: 1170px) {
  .reviews_head {
    font-size: 42px;
  }
}

@media only screen and (max-width: 969px) {
  .reviews_head {
    font-size: 34px;
  }
}

@media only screen and (max-width: 479px) {
  .reviews_head {
    font-size: 26px;
  }
}

.reviews_head_block {
  position: relative;
}

.reviews_carousel {
  margin-top: 110px;
}

@media only screen and (max-width: 767px) {
  .reviews_carousel {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 479px) {
  .reviews_carousel {
    margin-top: 40px;
  }
}

.reviews_carousel_prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 60px;
  width: 47px;
  height: 47px;
  background-color: #e72f02;
  transition: ease-in-out 0.2s;
  cursor: pointer;
}

@media only screen and (max-width: 479px) {
  .reviews_carousel_prev {
    width: 30px;
    height: 30px;
    right: 35px;
  }
}

.reviews_carousel_prev:hover {
  background-color: #fd3606;
}

.reviews_carousel_prev:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url("../img/sprite.png");
  width: 21px;
  height: 15px;
  background-position: -128px -75px;
}

.reviews_carousel_next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 47px;
  height: 47px;
  background-color: #e72f02;
  transition: ease-in-out 0.2s;
  cursor: pointer;
}

@media only screen and (max-width: 479px) {
  .reviews_carousel_next {
    width: 30px;
    height: 30px;
  }
}

.reviews_carousel_next:hover {
  background-color: #fd3606;
}

.reviews_carousel_next:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url("../img/sprite.png");
  width: 21px;
  height: 15px;
  background-position: -97px -75px;
}

.reviews_carousel_item {
  padding: 50px 53px 50px;
  background-color: white;
  box-shadow: 0 21px 57px rgba(0, 0, 0, 0.07);
}

@media only screen and (max-width: 969px) {
  .reviews_carousel_item {
    padding: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .reviews_carousel_item {
    padding: 20px 15px;
    box-shadow: none;
    border: 1px solid rgba(119, 119, 119, 0.05);
  }
}

.reviews_carousel_item_top {
  display: flex;
  align-items: center;
}

.reviews_carousel_item_img {
  display: block;
  float: left;
  border-radius: 50%;
  width: 57px;
  height: 57px;
}

.reviews_carousel_item_head {
  display: block;
  margin-left: 29px;
  color: #191817;
  font-family: mb;
  font-size: 20px;
  line-height: 40px;
}

@media only screen and (max-width: 479px) {
  .reviews_carousel_item_head {
    font-size: 16px;
  }
}

.reviews_carousel_item_content {
  display: block;
  margin-top: 40px;
  color: rgba(25, 24, 23, 0.7);
  font-family: mr;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

@media only screen and (max-width: 479px) {
  .reviews_carousel_item_content {
    font-size: 14px;
    line-height: 1.3;
  }
}

.contacts {
  margin-top: 205px;
  position: relative;
}

.contacts:before, .contacts:after {
  content: '';
  display: table;
  clear: both;
}

@media only screen and (max-width: 969px) {
  .contacts {
    margin-top: 100px;
  }
}

.contacts_map {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .contacts_map {
    position: relative;
    height: 320px;
  }
}

.contacts_block {
  z-index: 2;
  position: relative;
  margin: 110px 0 90px 5%;
  padding: 60px 65px 60px;
  width: 489px;
  height: 442px;
  background-color: white;
  box-shadow: 0 17px 68px rgba(53, 77, 91, 0.15);
}

@media only screen and (max-width: 969px) {
  .contacts_block {
    width: auto;
    height: auto;
    padding: 40px 30px;
    margin: 70px 0 70px 40px;
    float: left;
  }
}

@media only screen and (max-width: 767px) {
  .contacts_block {
    float: none;
    width: 100%;
    margin: 30px 0 0 0;
  }
}

.contacts_head {
  display: block;
  color: #1f1c1c;
  font-family: meb;
  font-size: 50px;
  line-height: 49px;
}

@media only screen and (max-width: 969px) {
  .contacts_head {
    font-size: 36px;
  }
}

.contacts_adress {
  display: block;
  margin-top: 65px;
  color: #414a59;
  font-family: mr;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
}

@media only screen and (max-width: 969px) {
  .contacts_adress {
    margin-top: 35px;
    font-size: 14px;
  }
}

.contacts_adress:before {
  content: '';
  display: inline-block;
  margin-right: 10px;
  background-image: url("../img/sprite.png");
  width: 12px;
  height: 16px;
  background-position: -20px -161px;
}

.contacts_phone {
  display: block;
  margin-top: 35px;
  color: #181819;
  font-family: mb;
  font-size: 26px;
  text-transform: uppercase;
}

.contacts_worktime {
  display: block;
  margin-top: 10px;
  color: rgba(24, 24, 25, 0.8);
  font-family: mr;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
}

.contacts_recall {
  display: block;
  margin-top: 55px;
  width: 266px;
  height: 57px;
  background-color: #e72f02;
  box-shadow: 0 13px 29px rgba(200, 39, 0, 0.42);
  line-height: 60px;
  text-align: center;
  transition: ease-in-out 0.2s;
  color: white;
  font-family: mr;
  font-size: 16px;
  font-weight: 400;
}

.contacts_recall:hover {
  background-color: #fd3606;
}

.checkform {
  display: none;
}

.checkform_head {
  display: block;
  text-align: center;
  font-size: 16px;
  font-family: mb;
}

.checkform_form:before, .checkform_form:after {
  content: '';
  display: table;
  clear: both;
}

.checkform_wrapper {
  margin-top: 15px;
  display: flex;
}

.checkform_wrapper:before, .checkform_wrapper:after {
  content: '';
  display: table;
  clear: both;
}

.checkform_input {
  display: block;
  float: left;
  flex: 1;
  height: 35px;
  border-radius: 5px 0 0 5px;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 0 10px;
  font-family: mr;
  font-size: 14px;
}

.checkform_submit {
  float: left;
  width: 100px;
  height: 35px;
  border: none;
  border-radius: 0 5px 5px 0;
  color: #fff;
  background: #e72f02;
  font-size: 14px;
  font-family: mr;
  cursor: pointer;
}

.recall {
  display: none;
}

.recall_head {
  display: block;
  text-align: center;
  font-size: 16px;
  font-family: mb;
}

.recall_input {
  display: block;
  height: 35px;
  width: 100%;
  max-width: 360px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 0 10px;
  font-family: mr;
  font-size: 14px;
  margin-top: 15px;
}

.recall_submit {
  margin-top: 15px;
  height: 35px;
  display: block;
  width: 100%;
  max-width: 360px;
  border-radius: 5px;
  background: #e72f02;
  color: #fff;
  font-size: 14px;
  border: none;
  cursor: pointer;
}
