* {
	box-sizing: border-box;
	outline: none;
	appearance: none; }

a {
	text-decoration: none;
	&:hover, &:active, &:visited {
		text-decoration: none; } }

body {
	font-family: mr, sans-serif;
	font-weight: normal;
	font-size: 14px;
	min-width: 320px;
	position: relative;
	line-height: 1;
	-webkit-font-smoothing: subpixel-antialiased;
	overflow-x: hidden;
	background: #fff; }

input, textarea {
	&::placeholder {
		color: inherit;
		font-size: inherit;
		font-family: inherit; } }

.hidden {
	display: none; }

.clearfix {
	@include clearfix;
	&:before {
		content: '';
		display: table;
		clear: both; } }

.wrapper {
	width: 1170px;
	margin: 0 auto;
	position: relative;
	@include cl;
	@include m(d) {
		width: 970px;
		padding: 0 15px; }
	@include m(t) {
		width: 768px; }
	@include m(t2) {
		width: 480px; }
	@include m(p1) {
		width: 320px; }
	@include m(p2) {
		width: 320px; } }
