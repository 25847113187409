$icons: (0:0);
$icons: map-merge($icons,('adv-item1': (X: 0px, Y:-69px, W: 69px, H: 69px, TW: 232px, TH: 180px, IMG: '../img/sprite.png')));
$icons: map-merge($icons,('adv-item2': (X: -97px, Y:0px, W: 65px, H: 65px, TW: 232px, TH: 180px, IMG: '../img/sprite.png')));
$icons: map-merge($icons,('adv-item3': (X: -172px, Y:0px, W: 60px, H: 68px, TW: 232px, TH: 180px, IMG: '../img/sprite.png')));
$icons: map-merge($icons,('adv-item4': (X: 0px, Y:0px, W: 87px, H: 59px, TW: 232px, TH: 180px, IMG: '../img/sprite.png')));
$icons: map-merge($icons,('adv-items-del': (X: 0px, Y:-148px, W: 68px, H: 3px, TW: 232px, TH: 180px, IMG: '../img/sprite.png')));
$icons: map-merge($icons,('contacts-place': (X: -20px, Y:-161px, W: 12px, H: 16px, TW: 232px, TH: 180px, IMG: '../img/sprite.png')));
$icons: map-merge($icons,('facebook': (X: 0px, Y:-161px, W: 10px, H: 19px, TW: 232px, TH: 180px, IMG: '../img/sprite.png')));
$icons: map-merge($icons,('facebook@2x': (X: -172px, Y:-78px, W: 20px, H: 38px, TW: 232px, TH: 180px, IMG: '../img/sprite.png')));
$icons: map-merge($icons,('form-galka': (X: -172px, Y:-126px, W: 8px, H: 6px, TW: 232px, TH: 180px, IMG: '../img/sprite.png')));
$icons: map-merge($icons,('place': (X: -42px, Y:-161px, W: 12px, H: 16px, TW: 232px, TH: 180px, IMG: '../img/sprite.png')));
$icons: map-merge($icons,('search-icon': (X: -202px, Y:-78px, W: 23px, H: 23px, TW: 232px, TH: 180px, IMG: '../img/sprite.png')));
$icons: map-merge($icons,('shop-next-icon': (X: -97px, Y:-100px, W: 19px, H: 13px, TW: 232px, TH: 180px, IMG: '../img/sprite.png')));
$icons: map-merge($icons,('slider-next': (X: -97px, Y:-75px, W: 21px, H: 15px, TW: 232px, TH: 180px, IMG: '../img/sprite.png')));
$icons: map-merge($icons,('slider-prev': (X: -128px, Y:-75px, W: 21px, H: 15px, TW: 232px, TH: 180px, IMG: '../img/sprite.png')));


// Gets an attribute from the sass map
@function icon-attr($icon, $attr) {
  $icon: map-get($icons, $icon);
  @return map-get($icon, $attr); }

@mixin sprite($iconName) {
  background-image: url(icon-attr($iconName, IMG));
  width: icon-attr($iconName, W);
  height: icon-attr($iconName, H);
  background-position: icon-attr($iconName, X) icon-attr($iconName, Y); }
@mixin sprite-position($iconName) {
  background-position: icon-attr($iconName, X) icon-attr($iconName, Y); }
@mixin sprite-retina($iconName) {
  background-image: url(icon-attr($iconName, IMG));
  $width: icon-attr($iconName, W);
  $height: icon-attr($iconName, H);
  width: $width/2;
  height: $height/2;
  $x: icon-attr($iconName, X);
  $y: icon-attr($iconName, Y);
  background-position: $x/2 $y/2;
  $tw: icon-attr($iconName, TW);
  $th: icon-attr($iconName, TH);
  background-size: $tw/2 $th/2; }


@mixin s($i) {
  @include sprite($i); }
@mixin sp($i) {
  @include sprite-position($i); }
@mixin sr($i) {
  @include sprite-retina($i); }

//
//.icon-adv-item1
//  width: 69px
//  height: 69px
//  background-image: url('../img/sprite.png')
//  background-position: 0px -69px
//
//.icon-adv-item2
//  width: 65px
//  height: 65px
//  background-image: url('../img/sprite.png')
//  background-position: -97px 0px
//
//.icon-adv-item3
//  width: 60px
//  height: 68px
//  background-image: url('../img/sprite.png')
//  background-position: -172px 0px
//
//.icon-adv-item4
//  width: 87px
//  height: 59px
//  background-image: url('../img/sprite.png')
//  background-position: 0px 0px
//
//.icon-adv-items-del
//  width: 68px
//  height: 3px
//  background-image: url('../img/sprite.png')
//  background-position: 0px -148px
//
//.icon-contacts-place
//  width: 12px
//  height: 16px
//  background-image: url('../img/sprite.png')
//  background-position: -20px -161px
//
//.icon-facebook
//  width: 10px
//  height: 19px
//  background-image: url('../img/sprite.png')
//  background-position: 0px -161px
//
//.icon-facebook@2x
//  width: 20px
//  height: 38px
//  background-image: url('../img/sprite.png')
//  background-position: -172px -78px
//
//.icon-form-galka
//  width: 8px
//  height: 6px
//  background-image: url('../img/sprite.png')
//  background-position: -172px -126px
//
//.icon-place
//  width: 12px
//  height: 16px
//  background-image: url('../img/sprite.png')
//  background-position: -42px -161px
//
//.icon-search-icon
//  width: 23px
//  height: 23px
//  background-image: url('../img/sprite.png')
//  background-position: -202px -78px
//
//.icon-shop-next-icon
//  width: 19px
//  height: 13px
//  background-image: url('../img/sprite.png')
//  background-position: -97px -100px
//
//.icon-slider-next
//  width: 21px
//  height: 15px
//  background-image: url('../img/sprite.png')
//  background-position: -97px -75px
//
//.icon-slider-prev
//  width: 21px
//  height: 15px
//  background-image: url('../img/sprite.png')
//  background-position: -128px -75px
//
